<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Phone, Bank Name"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Phone, Bank Name ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      track-by="hashed_id"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="checkbox-slot" slot-scope="prop">
          <input type="checkbox" @click="onChangeChecked(prop.rowData.id)" :value="prop.rowData.id" v-show="prop.rowData.listing === false && prop.rowData.status != 2 && prop.rowData.status != 1">
        </template>
        <template slot="name-slot" slot-scope="prop">
          <router-link :to="onAction('view-user', prop.rowData, prop.rowIndex)" target="_blank">{{ prop.rowData.name }}</router-link>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>{{ (Number(Number(prop.rowData.amount) - Number(prop.rowData.admin_fee))) | currency }}</span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button class="btn btn-info"
              @click="onAction('detail-item', prop.rowData, prop.rowIndex)">
              Detail
            </button>&nbsp;
            <!-- <button  v-if="$can('approve_withdraw')" v-show="prop.rowData.status != 2 && prop.rowData.status != 1 && prop.rowData.listing === false" class="btn btn-success" @click="onAction('approve', prop.rowData, prop.rowIndex)">Approve</button>&nbsp; -->
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
    <b-row v-if="$can('multiple_release')">
      <b-col>
        <button class="btn btn-success m-2" type="button" @click="multipleRelease">Release Multiple</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  // import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        selected: [],
        user: [],
        isLoading: false,
        largeModal: false,
        filterText: '',
        message: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `gold-sell-wallet`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'checkbox-slot',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: '',
            width: '5%'
          },
          {
            name: 'created_at',
            sortField: 'created_at',
            title: 'Request Date'
          },
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Nama Akun Profile'
          },
          {
            name: 'amount-slot',
            sortField: 'amount',
            title: 'Nilai Rupiah'
          },
          {
            name: 'status',
            sortField: 'status',
            formatter: this.statusField
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    methods: {
      onChangeChecked(id) {
        let arr = this.selected.includes(id)
        if (arr !== true) {
          this.selected.push(id)
        }
      },
      multipleRelease() {
        let user = this.selected
        this.isLoading = true
          this.$http.post(`multiple-gold-sell-wallet`, {
              user: user
          }).then(() => {
              this.isLoading = false
              this.$swal.fire(
                'Success!',
                'Dana Berhasil dikirim!',
                'success'
              ).then(() => {
                location.reload()
              })
          }).catch((error) => {
              if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status  == 400) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }else if (this.errors.status  == 404) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }
              }
          })
      },
      statusField(value) {
        return value == 0 ? 'Sedang Diproses' : (value == 1 ? 'Berhasil' : (value == 2 ? 'Gagal' : '-'))
      },
      goldField(value) {
        return value + ' Gram'
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`withdraw/gold-sell-wallet/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading =false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'view-user') {
          return { name: 'Detail End User', params: { id: btoa(data.user_id)} }
        }else if (action == "detail-item") {
          this.$router.push({name: 'Detail Jual Emas Celengan', params:{id: data.hashed_id}})
        }else{
          this.isLoading = true
          this.$http.post(`set-success-gold-sell-wallet/` + data.hashed_id).then(() => {
              this.isLoading = false
              this.$swal.fire(
                'Success!',
                'Dana Berhasil dikirim!',
                'success'
              ).then(() => {
                location.reload()
              })
          }).catch((error) => {
              if (error.response) {
                  this.isLoading = false
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status  == 400) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }else if (this.errors.status  == 404) {
                      this.$swal.fire(
                          'Failed',
                          this.errors.message,
                          'error'
                      ).then(() => {
                          location.reload()
                      })
                  }
              }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  footer.modal-footer {
      display: none;
  }

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
